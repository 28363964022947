<template>
  <div>
    <div>
      <h1
        class="header-text pb-3"
        ref="headerEditProfile"
        aria-labelledby="header for Usage Descriptions page"
        data-cy="header-admin-reports-usage-desc"
        autofocus
      >
        Usage Descriptions
      </h1>
    </div>
    <div class="flex">
      <div class="flex-auto">
        <div class="mb-2">Term</div>
        <select class="mb-2 w-1/2 rounded border-dark-gray py-1" v-model="term">
          <option class="h-8" selected>Select a Term...</option>
          <option
            class="h-8"
            v-for="(term, key) in terms"
            :key="key"
            :value="term"
          >
            {{ term.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="box-style mb-6">
      The Usage Description Report produces a significant amount of data that
      cannot be dislayed on a web page. A CSV will be sent via email to you when
      complete To begin the process, choose from the menu above.
    </div>
  </div>
</template>

<script>
import { get } from "@/composables/httpUtil";
import { mapActions } from "vuex";

export default {
  name: "UsageDescriptions",
  data() {
    return {
      term: null,
      terms: [],
    };
  },
  mounted() {
    this.fetchLookupResource().then(
      get("admin/terms")
        .then((terms) => {
          this.terms = terms.data;
        })
        .finally(() => {
          this.$watch(
            (vm) => vm.term,
            () => {
              this.isLoading = true;
              this.getData();
            },
            { immediate: false }
          );
          this.isLoading = false;
        })
    );
  },
  methods: {
    getData() {
      let url = `admin/reports/usage_descriptions?term=${this.term.term_id}`;
      get(url).catch((error) => {
        console.log("error in catch statement", error);
      });
    },
    ...mapActions(["fetchLookupResource"]),
  },
};
</script>

<style scoped></style>
